// Step 1: Import React
import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";

// Step 2: Define your component
const IndexPage = () => {
    return (
        <Layout pageTitle="BurkeMelAcres">
            <h1>Under Construction</h1>
            <StaticImage
                //className={image}
                alt="forest"
                src="../images/2.jpg"
            />
        </Layout>
    );
};
// Step 3: Export your component
export default IndexPage;
